.tbody{
    background-color: rgb(35,35,41);
    border: none;

}
.tbody thead {
    background-color: rgb(35,35,41);
  border-bottom: 2px gray solid;

}
.tbody thead tr{
    background-color: rgb(35,35,41);
    border: none;

}
.table {
    border: none !important; 
    /* border-collapse: collapse; */
}
.table, th, td {
    border: none !important;
    /* border-collapse: collapse; */
}
.dots{
  color:white !important;
}
.tbody thead tr th{
    background-color:  rgb(35,35,41);
    color: white;
    border: none;
}
.tbody tbody{
    background-color: rgb(35,35,41);
    color: white;
}
.tbody tr{
    background-color: rgb(35,35,41);
    color: white;
    border-bottom: 1px gray solid;
    text-align: left;
    

}
.tbody tr td{
    background-color: rgb(35,35,41);
    color: white;
    text-align: left;

  line-height: 40px; 
 
    border: none;
}

.hover-date {
    position: relative;
    cursor: pointer;
    padding-bottom: 4px; 
  }
  .date-on-hover {
    display: none;
    position: absolute;
 
  left: 50%;
  transform: translateX(-50%);
  background-color: #f8f7f7e5;
  color: #2e2d2d;
  padding: 2px 2px;
  border-radius: 3px;
  font-size: 12px;
  z-index: 1;
  margin-top: 2px; 
}
  
  .hover-date:hover .date-on-hover {
    display: flex;
    justify-content: space-around; 
    width: max-content!important;
  }