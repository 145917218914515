.tbody{
    background-color: gray;
}
.tbody thead {
    background-color: gray;
}
.tbody thead tr{
    background-color: gray;
}
.tbody thead tr th{
    background-color:  rgb(15 23 42);
    color: white;
    border: none;
}
.tbody tbody{
    background-color: gray;
    color: white;
}
.tbody tr{
    background-color: gray;
    color: white;
    border: none;
}
.tbody tr td{
    background-color: gray;
    color: white;
    border: none;
}

.hover-date {
    position: relative;
    cursor: pointer;
    /* padding-bottom: 4px;  */
  }
  .date-on-hover {
    display: none;
    position: absolute;
    width: 90% !important;
    height: 4vh;
    align-items: center;

    left: 35%;
    transform: translateX(-40%);
  background-color: #f8f7f7e5;
  color: #2e2d2d;
  /* padding: 2px 2px; */
  border-radius: 3px;
  font-size: 12px;
  z-index: 1;
  /* margin-top: 2px;  */
}
  
  .hover-date:hover .date-on-hover {
    display: flex;
    justify-content: space-around; 
    width: 100px;
  }
  .dots{
    color:white !important;
  }