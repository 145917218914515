.pagination li{
    display: flex;
    gap: 1rem;
    /* color: var(--secondary-color); */
    align-items: center;
    list-style: none;
    font-size: 14px;
    /* font-family: var(--poppins-regular); */
    font-weight: 500;
  }
  .pagination .active{
    color: yellow;
    border-bottom: 1px solid yellow;
  }