@tailwind base;
@tailwind components;
@tailwind utilities;

.loading-indicator:before {
    content: '';
    background: #00000080;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .loading-indicator:after {
    content: ' ';
    position: fixed;
    top: 40%;
    left: 45%;
    z-index: 10010;
    color:white;
    text-align:center;
    font-weight:bold;
    font-size:1.2rem;        
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #0474bf; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}
.nav-tabs .nav-link {
  color: white !important;
}
.nav-tabs .nav-link:hover {
  color: black !important;
}
.nav-tabs .nav-link.active{
  color: black !important;
}

.zoom-in-on-hover {
  transition: transform 0.5s ease-in-out; /* Add a smooth transition effect */
}

.zoom-in-on-hover:hover {
  transform: scale(1.2); /* Zoom in by 10% on hover (adjust as needed) */
}

.css-1uf5e3a-control{
  background-color: transparent !important;
}
.selectDropdpenReact{
  
  border: 1px solid #ffff;
  border-radius: 5px;
}
.css-1uccc91-singleValue{
  color: #9BA3AF !important;
}
