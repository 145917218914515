.overflow-y-auto::-webkit-scrollbar {
    width: 0.5em; 
  }
  
  .overflow-y-auto::-webkit-scrollbar-thumb {
    background-color: transparent; 
  }
  
  .overflow-y-auto::-webkit-scrollbar-track {
    background-color: transparent;
  }